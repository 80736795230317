(function($) {
    "use strict";

    var currentDoc = $(document),
        currentWindow = $(window),
        language = $('html').attr('lang');

    var ccMessage = "Wir verwenden Cookies, um die Website für Sie optimal zu gestalten und an Ihren Interessen auszurichten. Mit der weiteren Nutzung der Website stimmen Sie der Verwendung von Cookies zu. ",
		ccDismiss = "OK!",
		ccLink = "Informationen",
		ccHref = "https://www.rigk.de/rechtliches/datenschutz/";

	if(language == 'en') {

	    ccMessage = 'en',
		ccDismiss = 'en',
		ccLink = 'en',
		ccHref = 'en';

	} else if(language == 'es'){

	    ccMessage = 'es',
		ccDismiss = 'es',
		ccLink = 'es',
		ccHref = 'es';

	} else if(language == 'fr'){

	    ccMessage = 'fr',
		ccDismiss = 'fr',
		ccLink = 'fr',
		ccHref = 'fr';
	}




	window.addEventListener("load", function(){
	window.cookieconsent.initialise({
	  "palette": {
	    "popup": {
	      "background": "#cbdbee",
	      "text": "#3d6372"
	    },
	    "button": {
	      "background": "#0079ba"
	    }
	  },
	  "theme": "classic",
	  "content": {
	    "message": ccMessage,
	    "dismiss": ccDismiss,
	    "link": ccLink,
	    "href": ccHref
	  }
	})});

    currentDoc.ready(function() {

    	var menueItem = $('.nav-link.arrow, .search-opener .nav-link');

    	menueItem.on('click', function (e) {
    	    e.preventDefault();

    	    var firstLvlParent = $(this).parents('.nav-item').last();
    	    var firstLvlNavItems = $('.navbar-nav > li.nav-item').not(firstLvlParent);
    	    firstLvlNavItems.find('.nav-item').removeClass('open');

    	    $(this).parent('.nav-item').siblings().removeClass('open');
    	    $(this).parent('.nav-item').toggleClass('open');

    	    if(xl == true) {
	    	    var childHeight = $(this).parent('.nav-item').find('ul').first().height();
	    	    var navHeight = $('.nav-lvl-2').height();

	    	    var parentsMaxHeight = 0;
	    	    var maxHeight = 0;

	    	    $(this).parent('.nav-item').parents('ul').each(function(){
	    	    	if($(this).height()>parentsMaxHeight)
	    	    	{
	    	    		parentsMaxHeight = $(this).height();
	    	    	}
	    	    });

	    	    if (parentsMaxHeight>childHeight) {
					maxHeight=parentsMaxHeight;
				}
				else {
					maxHeight=childHeight;
				}

	    	    if (maxHeight>=navHeight) {
					$(this).parents('.nav-lvl-2').height(maxHeight);
				}
				else {
					$(this).parents('.nav-lvl-2').removeAttr('style');
				}
			}
			else {
				$(this).parents('.nav-lvl-2').removeAttr('style');
			}

			if ($('.search-opener.open')) {
				$('.search-positioner').removeClass('search-open');
			} else {
				$('.search-positioner').addClass('search-open');
			}
    	});

    	if(xl == true) {
	    	$('html').click(function() {
			    $('.nav-item.open').removeClass('open');
			    $('.lang-switch').removeClass('open');
			    $('.search-positioner').removeClass('search-open');
			});

			$('.navbar-collapse, .lang-switch').click(function(event){
			    event.stopPropagation();
			});
    	}

    	$('.search-opener').on('click', function (e) {
    	    e.preventDefault();
    	    $('.search-positioner').toggleClass('search-open');
    	});


		var selectSystem = $('#system');
    	var selectFuellgut = $('#fuellgut');
		var results = $('.ek-lvl-2');

		selectFuellgut.change(function () {

			$('.ek-form input:radio:checked').each(function(){
		        $(this).prop('checked', false);
		    });

		    if ($(this).val() == 'ekv-1') {
		        results.addClass('ekv-1');
		        results.removeClass('ekv-2');
		    }
		    else if ($(this).val() == 'ekv-2') {
		        results.addClass('ekv-2');
		        results.removeClass('ekv-1');
		    }
		    else {
		    	results.removeClass('ekv-1');
		    	results.removeClass('ekv-2');
		    }
		});
		selectSystem.change(function () {

			$('.ek-form input:radio:checked').each(function(){
		        $(this).prop('checked', false);
		    });

		    if ($(this).val() == 'ekv-3') {
		        results.addClass('ekv-3');
		        results.removeClass('ekv-4');
		    }
		    else if ($(this).val() == 'ekv-4') {
		        results.addClass('ekv-4');
		        results.removeClass('ekv-3');
		    }
		    else {
		    	results.removeClass('ekv-3');
		    	results.removeClass('ekv-4');
		    }
		});

		// Langswitch öffner
		$('.lang-switch').on('click', function () {
			$(this).toggleClass('open');
		});

		// ToTop Button
		var btt = ['<div class="btt-wrap"><a href="#top" class="btt"></a></div>'].join("");
	    $(".footer").append(btt)
	    $(".btt").hide();
	    $(function () {
	    	currentWindow.scroll(function () {
	    		if ($(this).scrollTop() > 100) {
	    			$('.btt').fadeIn();
	    		} else {
	    			$('.btt').fadeOut();
	    		}
	    	});

	    	$('.btt').click(function () {
	    		$('body,html').animate({
	    			scrollTop: 0
	    		}, 800);
	    		return false;
	    	});
		});

    	$('.newsletter-submit').on('click', function (e) {
    	    e.preventDefault();

    	    $('.newsletter-form').submit();
    	})

    	$(".dotdotdot").dotdotdot({
    		keep: "a, svg"
	    });

    	$('.header-slider').on('init', function() {
            $('.preloader').delay(1500).fadeOut(800);
        });

	    $('.header-slider').slick({
	    	dots: true,
	    	prevArrow: '<button type="button" class="slick-prev"><i class="fa fa-angle-left"></i></button>',
		    nextArrow: '<button type="button" class="slick-next"><i class="fa fa-angle-right"></i></button>',
		    adaptiveHeight: true,
		    autoplay: true,
		    autoplaySpeed: 5000,
		    responsive: [
		    	{
		            breakpoint: 768,
		            settings: {
		            	autoplay: false
		            }
		        },
		    ]
	    });

	    $('.news-slider').slick({
		    dots: false,
		    speed: 300,
		    slidesToShow: 4,
		    slidesToScroll: 4,
		    adaptiveHeight: true,
		    prevArrow: '<button type="button" class="slick-prev"><i class="fa fa-angle-left"></i></button>',
		    nextArrow: '<button type="button" class="slick-next"><i class="fa fa-angle-right"></i></button>',
		    settings: "unslick",
		    responsive: [
		    	{
		            breakpoint: 1200,
		            settings: {
		                slidesToShow: 4,
		                slidesToScroll: 4
		            }
		        },
		        {
		            breakpoint: 992,
		            adaptiveHeight: true,
		            settings: {
		                slidesToShow: 3,
		                slidesToScroll: 3
		            }
		        },
		        {
		            breakpoint: 768,
		            adaptiveHeight: true,
		            settings: {
		                slidesToShow: 2,
		                slidesToScroll: 2
		            }
		        },
		        {
		            breakpoint: 576,
		            adaptiveHeight: true,
		            settings: {
		                slidesToShow: 1,
		                slidesToScroll: 1
		            }
		        }
		    ]
		});

		$('.validate').parsley();

		var langDatepicker = $('html').attr('lang'),
		    year = (new Date).getFullYear();
		if (langDatepicker == 'en') {
			langDatepicker = 'en-GB'
		}

		$('#period').on('change', function () {
		    year = $(this).val();
		    $( "#dateStart" ).val('');
		    $( "#dateStart" ).datepicker( "destroy" );
		    $( "#dateEnd" ).val('');
		    $( "#dateEnd" ).datepicker( "destroy" );
		    initDatepicker();
		})

		initDatepicker();

		function initDatepicker() {

		    $.datepicker.setDefaults($.datepicker.regional[langDatepicker]);

			var dateFormat = $.datepicker.regional[langDatepicker].dateFormat,
	        dateFrom = $( "#dateStart" )
	        .datepicker({
	            defaultDate: "+1w",
	            changeMonth: true,
	            numberOfMonths: 1,
	            minDate: new Date(year, 1-1, 1),
	      		maxDate: new Date(year, 12-1, 31)
	        })
	        .on( "change", function() {
	            dateTo.datepicker( "option", "minDate", getDate( this ) );
	        }),
	        // .on( "change", function() {
	        //     dateTo.datepicker( "option", {
	        //     	minDate: getDate( this ),

	        //     });
	        // }),
	        dateTo = $( "#dateEnd" ).datepicker({
	            defaultDate: "+1w",
	            changeMonth: true,
	            numberOfMonths: 1,
	            minDate: new Date(year, 1-1, 1),
	      		maxDate: new Date(year, 12-1, 31)
	        })
	        .on( "change", function() {
	            dateFrom.datepicker( "option", "maxDate", getDate( this ) );
	        });

		    function getDate( element ) {
		        var date;
		        try {
		          date = $.datepicker.parseDate( dateFormat, element.value );
		        } catch( error ) {
		          date = null;
		        }

		        return date;
		    }
		}

    });


})(jQuery);